body {
  background-color: white;
  color: black;
  font-family: monospace;
}

h1 {
  font-size: 24pt;
}

h2 {
  font-size: 20pt;
}

h3 {
  font-size: 18pt;
}
